/*******************************************************************************
 * Styles for the base elements of the theme.
 ******************************************************************************/

// Typography

p, h1, h2, h3, h4, h5, h6, em, div, li, span, strong {
  color: var(--global-text-color);
   font-family: 'Lato';
}

p {
  line-height: 1.65rem;
}

.post-header {
  margin-botom: 2rem;
}

hr {
  border-top: 1px solid var(--global-divider-color);
}

table {
  td, th {
    color: var(--global-text-color);
  }
  td {
    font-size: 1rem;
  }
}

a, table.table a {
  color: var(--global-theme-color);
  &:hover {
    color: var(--global-theme-color);
    text-decoration: underline;
  }
  &:hover:after :not(.nav-item.dropdown) {
    width: 100%;
  }
}

figure, img {
  max-width: 90vw;
}

blockquote {
  background: var(--global-bg-color);
  border-left: 2px solid var(--global-theme-color);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-size: 1.2rem;
}

// Math

.equation {
  margin-bottom: 1rem;
  text-align: center;
}

// Caption

.caption {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

// Card

.card {
  background-color: var(--global-card-bg-color);

  img {
    width: 100%;
  }

  .card-title {
    color: var(--global-text-color);
  }

  .card-item {
    width: auto;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
    }
  }
}

// Citation

.citation, .citation-number {
  color: var(--global-theme-color);
}

// Profile

.profile {
  width: 100%;

  .address {
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: monospace;
    p {
      display: inline-block;
      margin: 0;
    }
  }
}
.profile.float-right{
  margin-left: 1rem;
}
.profile.float-left{
  margin-right: 1rem;
}

@media (min-width: 576px) {
  .profile {
    width: 30%;
    .address {
      p { display: block; }
    }
  }
}

.post-description {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  a {
    color: inherit;
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}


// Navbar customization

.navbar {
  box-shadow: none;
  border-bottom: 1px solid var(--global-divider-color);
  background-color: var(--global-bg-color);
  opacity: 0.95;
}
.navbar .dropdown-menu {
  background-color: var(--global-bg-color);
  border: 1px solid var(--global-divider-color);
  a:not(.active) {
    color: var(--global-text-color);
  }
  a:hover {
    color: var(--global-hover-color);
  }
  .dropdown-divider {
    border-top: 1px solid var(--global-divider-color) !important;
  }
}
.dropdown-item {
  color: var(--global-text-color);
    &:hover {
      color: var(--global-hover-color);
      background-color: var(--global-bg-color);
    }
}
.navbar.navbar-light {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-brand {
    color: var(--global-text-color);
  }
  .navbar-nav .nav-item .nav-link {
    color: var(--global-text-color);
    &:hover {
      color: var(--global-hover-color);
    }
  }
  .navbar-nav .nav-item.active>.nav-link {
      background-color: inherit;
      font-weight: bolder;
      color: var(--global-theme-color);
      &:hover {
        color: var(--global-hover-color);
      }
  }
  .navbar-brand.social {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 1.7rem;
    a {
      i::before {
        color: var(--global-text-color);
        transition-property: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
}

.navbar-toggler {
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    background-color: var(--global-text-color);
    border-radius: 1px;
    margin-bottom: 4px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

#light-toggle {
  padding: 0;
  border: 0;
  background-color: inherit;
  color: var(--global-text-color);
  &:hover {
    color: var(--global-hover-color);
  }
}

// Social (bottom)

.social {
  text-align: center;
  .contact-icons {
    font-size: 4rem;
    a {
      i::before {
        color: var(--global-text-color);
        transition-property: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
  .contact-note {
    font-size: 0.8rem;
  }
}


// Footer
footer.fixed-bottom {
  background-color: var(--global-footer-bg-color);
  font-size: 0.75rem;
  .container {
    color: var(--global-footer-text-color);
    padding-top: 9px;
    padding-bottom: 8px;
  }
  a {
    color: var(--global-footer-link-color);
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}

footer.sticky-bottom {
  border-top: 1px solid var(--global-divider-color);
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.9rem;
}

// CV

.cv {
  margin-bottom: 40px;
  
  .card {
    background-color: var(--global-card-bg-color);
    border: 1px solid var(--global-divider-color);
    
    .list-group-item {
      background-color: inherit;
      border-color: var(--global-divider-color);

      .badge {
        color: var(--global-card-bg-color) !important;
        background-color: var(--global-theme-color) !important;
      }
    }
  }
}

// Repositories

@media (min-width: 768px) {
  .repo {
    max-width: 50%;
  }
}

// Blog

.header-bar {
  border-bottom: 1px solid var(--global-divider-color);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 5rem;
  }
}

.tag-list {
  border-bottom: 1px solid var(--global-divider-color);
  text-align: center;
  padding-top: 1rem;

  ul {
    justify-content: center;
    display: flow-root;

    p, li {
      list-style: none;
      display: inline-block;
      padding: 1rem 0.5rem;
      color: var(--global-text-color-light);
    }
  }
}

.post-list {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  li {
    border-bottom: 1px solid var(--global-divider-color);
    list-style: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .post-meta {
      color: var(--global-text-color-light);
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    .post-tags {
      color: var(--global-text-color-light);
      font-size: 0.875rem;
      padding-top: 0.25rem;
      padding-bottom: 0;
    }
    a {
      color: var(--global-text-color);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      color: var(--global-text-color);
      &:hover {
        color: $black-color;
      }
    }
    &.active .page-link {
      color: $white-color;
      background-color: var(--global-theme-color);
      &:hover {
        background-color: var(--global-theme-color);
      }
    }
  }
}


// Distill

.distill {
  a:hover {
    border-bottom-color: var(--global-theme-color);
    text-decoration: none;
  }
}


// Projects

.projects {
  a {
    text-decoration: none;

    &:hover {
      .card-title {
        color: var(--global-theme-color);
      }
    }
  }

  .card {
    img {
      width: 100%;
    }
  }

  .card-item {
    width: auto;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
    }
  }

  .grid-sizer, .grid-item {
    width: 250px;
    margin-bottom: 10px;
  }

  h2.category {
    color: var(--global-divider-color);
    border-bottom: 1px solid var(--global-divider-color);
    padding-top: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: right;
  }
}


// Publications

.publications {
  margin-top: 2rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: var(--global-divider-color);
    border-top: 1px solid var(--global-divider-color);
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .preview {
        width: 100%;
        min-width: 80px;
        max-width: 200px;
      }
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: var(--global-theme-color);
          padding-left: 1rem;
          padding-right: 1rem;
          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .award {
          color: var(--global-theme-color) !important;
          border: 1px solid var(--global-theme-color);
        }
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
        > span.more-authors {
          color: var(--global-text-color-light);
          border-bottom: 1px dashed var(--global-text-color-light);
          cursor: pointer;
          &:hover {
              color: var(--global-text-color);
              border-bottom: 1px dashed var(--global-text-color);
          }
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px solid var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        transition-property: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        transition-property: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px var(--global-bg-color);
      }
      div.abstract.hidden.open {
        border-color: var(--global-text-color);
      }
    }
  }
}

// Rouge Color Customization
figure.highlight {
  margin: 0 0 1rem;
}

pre {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 6px;
  padding: 6px 12px;
  pre, code {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

code {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 3px;
  padding: 3px 3px;
}


// Transitioning Themes
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

// Extra Markdown style (post Customization)
.post{
  .post-meta{
    color: var(--global-text-color-light);
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  .post-tags{
    color: var(--global-text-color-light);
    font-size: 0.875rem;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    a {
      color: var(--global-text-color-light);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
  .post-content{
    blockquote {
      border-left: 5px solid var(--global-theme-color);
      padding: 8px;
    }
  }
}

progress {
     /* Positioning */
     position: fixed;
     left: 0;
     top: 56px;
     z-index: 10;
   
     /* Dimensions */
     width: 100%;
     height: 1px;
   
     /* Reset the appearance */
     -webkit-appearance: none;
        -moz-appearance: none;
             appearance: none;
   
     /* Get rid of the default border in Firefox/Opera. */
     border: none;
   
     /* Progress bar container for Firefox/IE10  */
     background-color: transparent;
   
     /* Progress bar value for IE10  */
     color: var(--global-theme-color);
 }
 
 progress::-webkit-progress-bar {
     background-color: transparent;
 }
   
 progress::-webkit-progress-value {
     background-color: var(--global-theme-color);
 }
   
 progress::-moz-progress-bar {
     background-color: var(--global-theme-color);
 }
 
 .progress-container {
     width: 100%;
     background-color: transparent;
     position: fixed;
     top: 56px;
     left: 0;
     height: 5px;
     display: block;
 }
 
 .progress-bar {
     background-color: var(--global-theme-color);
     width: 0%;
     display: block;
     height: inherit;
 }
